import { useContext, useEffect, useState } from 'react';
import './App.css';
import Main from './components/Main';
import NotFound from './components/NotFound';
import { ToastContainer } from "react-toastify";
import { Routes, Route } from "react-router-dom"
export default function App() {

  useEffect(() => {

  }, [])

  return (
    <>
      <ToastContainer
        bodyClassName={() => "myToastClass"}
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={true}
        rtl={true}
      />
      <Routes>
        <Route path='/' element={<Main />} />
        <Route path='/main' element={<Main />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

