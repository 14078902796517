import { useContext, useEffect, useState } from 'react';
 
export default function NotFound() {

  useEffect(() => {
    
  }, [])

  return (
    <>
   NotFound
      </>
  );
}

