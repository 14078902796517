import axios from "axios";
import { toast } from "react-toastify";

const tokenKey = "tokenCrm2";
const cardKey = "card";
const loginPath = "/Login"
const googleToken = 'googleToken2';
const langKey="lang"

 
 

const handleChange = (event, form,setForm) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setForm({ ...form, [name]: value });
  };



function setJwt(jwt) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + jwt;
}

function goLogin() {
    window.location = loginPath;
}

axios.interceptors.response.use((response) => {
    const { data } = response;
    console.log("axios data", data);
    return response;
}
    , error => {
        console.log("axios Error", error);
        /*const expectedError =
            error.response &&
            error.response.status >= 400 &&
            error.response.status < 500;*/
        if (error.response && error.response.status == 401) {
            logout();
            toast.error("حساب شما منقضی شده است مجددد وارد شوید");
            return Promise.reject(error);
            //goLogin();
        } else
            toast.error('خطا در  اطلاعات', { theme: "colored" });
        return Promise.reject(error);
    });



function getGoogleToken() {
    return localStorage.getItem(googleToken);
}

function setGoogleToken(jwt) {
    localStorage.setItem(googleToken, jwt);
}
 
function getLocalJwt() {
    return localStorage.getItem(tokenKey);
}
function setLocalJwt(jwt) {
    localStorage.setItem(tokenKey, jwt);
}

function getLang() {
    let temp= localStorage.getItem(langKey);
    if (temp=="" || temp==null)
        temp="1";
    return temp
}
function setLang(lan) {
    localStorage.setItem(langKey, lan);
}


function logout() {
    localStorage.removeItem(tokenKey);
    localStorage.removeItem(googleToken);
}

function getCard() {
    const tempCard = localStorage.getItem(cardKey);
    let card = tempCard != null && tempCard != "" ? JSON.parse(tempCard) : [];
    return card;
}

function saveCard(data) {
    localStorage.setItem(cardKey, JSON.stringify(data));
}

function addSaveCard(card) {
    let tempCard = localStorage.getItem(cardKey);
    //console.log('1', tempCard);
    //console.log('2', tempCard != null && tempCard != "" ? "**" : "*");
    let oldCard = tempCard != null && tempCard != "" ? JSON.parse(tempCard) : [];
    console.log('oldCard', oldCard);
    let newCard = oldCard == null ? [...card] : [...oldCard, ...card];
    console.log('newCard', newCard);
    saveCard(newCard);
    //console.log(localStorage.getItem('card'));
}

function deleteCard(id) {
    let tempCard = localStorage.getItem(cardKey);

    let oldCard = tempCard != null && tempCard != "" ? JSON.parse(tempCard) : [];
    if (oldCard == [])
        return;

    console.log('oldCard', oldCard);
    let article = oldCard.find(m => m.id === id);
    oldCard.splice(oldCard.indexOf(article), 1);

    console.log('article', article);
    console.log('oldCard', oldCard);
    saveCard(oldCard);
    return article;
}

function emptyCard() {
    localStorage.removeItem(cardKey);
}


function getGeolocation(showError, onGet) {
    navigator.geolocation.getCurrentPosition((position) => {
        console.log("position:", position);
        onGet(position);

    }, (error) => {
        if (showError)
            toast.error('جهت دسترسی به موقغیت یاب سرور شما به ارتباط امن https متصل نیست' + + error.message);
        console.error("Error 111 = " + error.code + " - " + error.message);
    });
}

function onErrorImage(ev) {
    //console.log('onerror');
    ev.target.src = '/images/defaultArticle.png'
}

function onLoadImage(ev) {
    //console.log('onLoadImage');
    //ev.target.src = '/images/defaultArticle.png';
    //console.log(ev);
    //ev.onLoad=null;
}


export default {
    setGoogleToken,getGoogleToken,

    onErrorImage,
    onLoadImage,

    getGeolocation,
    handleChange,

    logout,
    getLocalJwt,
    setLocalJwt,
    goLogin,



    getCard,
    addSaveCard,
    saveCard,
    emptyCard,
    deleteCard,

    axiosInstance: axios,
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    setJwt,

    setLang,getLang
};
