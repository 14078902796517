import config from "../config.json";
import http from "../Services/httpService";
  
function master(data) {
    //http.setJwt(http.getLocalJwt());
    return http.post(`${config.apiUrl}/api/master`,data);
} 

function lat_long(data) {
    //http.setJwt(http.getLocalJwt());
    return http.post(`${config.apiUrl}/api/lat_long`,data);
} 



function point(data) {
    //http.setJwt(http.getLocalJwt());
    return http.post(`${config.apiUrl}/api/point`,data);
} 
 
 

export default {
    master,lat_long,point


     
};
