
import { Dropdown } from 'react-bootstrap';
//import profilePic from '../Images/profilePic.png'
import persianMap from '../Images/persianMap.png'
import englishMap from '../Images/englishMap.png'

import icon1 from '../Images/icon1.png'
import icon2 from '../Images/icon22.png'
import iconExpand from '../Images/icon22Expand.png'


import http from "../Services/httpService";
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import React, { ChangeEvent, FocusEvent, useEffect, useState } from "react";

export default function HeaderMain({ loading, data, category, onChangeCategory }) {

  useEffect(() => {

  }, [])

  const [direction,setDirection]=useState("start");
  useEffect(() => {
    const lang = http.getLang();
    document.body.className = lang == "1" ? 'customRtl' : 'customLtr';
    setDirection(lang == "1" ? 'start' : 'end')
    return () => { document.body.className = ''; }
  }, []);
  const [expand, setExpand] = useState(true);

  return (
    <>
      <div className='row leaflet-control ' id="customHeader"              >
        <div className='col'>
          <div className='row'>
            <div className='col'>
              <div className='row'>
                <div className='col-auto'>
                  <img src={data.userpic} id='userPic' />
                </div>
                <div className='col'>
                  {/*loading &&
                    <div className="spinner-border spinner-border-sm" role="status">
                      <span className="visually-hidden">Loading...</span>
  </div>*/}
                  <div className='d-flex d-row fs1'>{data.username}</div>
                  <div className='d-flex d-row fs2'>{data.userHint}</div>
                </div>
              </div>
            </div>
            <div className='col-auto d-flex justify-content-end align-items-center'>
              <div className=' d-flex'>
                <div><img src={icon1} className='me-2' /></div>
                <Dropdown
                  className="navbar-nav"
                  drop={direction}
                  onSelect={eventKey => {
                    http.setLang(eventKey);
                    window.location.reload();
                  }}
                >
                  <Dropdown.Toggle id="dropdown-custom-components">
                    <img src={http.getLang()=="1"? persianMap:englishMap} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="myDropDown">
                    <Dropdown.Item eventKey={1}> <img src={persianMap} />فارسی</Dropdown.Item>
                    <Dropdown.Item eventKey={2}> <img src={englishMap} />English</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>

          {expand &&
            <div className='row mt-2 p-2  overflow-x-auto'>
              <div className='col d-flex '>
                {'category' in data && data.category.map((x, i) => (
                  <div className={`col-auto me-2 px-2 py-0 itemFilter fs3 ${category == x.id ? "border-primary" : ""}`}
                    key={i + "filterItem"}
                    onClick={e => {
                      //e.preventDefault();
                      onChangeCategory(x);
                    }}>
                    {x.name}
                  </div>
                ))}
              </div>
            </div>
          }
          <div className='row  d-flex justify-content-center'>
            <div className='col d-flex justify-content-center' id="colButtonTop"  >
              <Link
                className='d-flex justify-content-center'
                to=""
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setExpand(p => !p);
                }}>
                <img src={expand ? iconExpand : icon2} className=' ' id="buttonTop" />
              </Link>
            </div>
          </div>
        </div>
      </div>


    </>
  );
}

