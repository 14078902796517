import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import HeaderMain from './HeaderMain'
import FooterMain from './FooterMain'
import currentLocation2 from '../Images/currentLocation.png'
import './Main.css'
import L from 'leaflet';
import {
  MapContainer, TileLayer, Marker, Popup, useMapEvents, LayersControl
  , LayerGroup, Circle, Rectangle, FeatureGroup, useMap, useMapEvent, Polyline,
} from 'react-leaflet'

import React, { ChangeEvent, FocusEvent, useEffect, useState, useMemo } from "react";

import { Link, useLocation, useSearchParams, useParams, useNavigate } from 'react-router-dom';


import marker1 from '../Images/marker1.png';
import markerSelect from '../Images/markerSelect.png';

import http from "../Services/httpService";
import services from "../Services/services";
import config from "../config.json";
import { toast } from "react-toastify";

export default function Main() {
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [centerMap, setCenterMap] = useState(
    {
      lat: 41.03703,
      lng: 28.98509
    });

  const [dataMaster, setDataMaster] = useState({})
  const [itemFocuse, setItemFocuse] = useState({})
  const [isLoading, setIsLoading] = useState(false);
  const [doExpand, setDoExpand] = useState(false)
  const [idPoint, setIdPoint] = useState("");
  const [category, setCategory] = useState(1);
  useEffect(() => {
    const point = searchParams.get('point');
    if (point != "" && point != null) {
      setIdPoint(point)
      setCategory(0)
    } else
      master();
  }, []);

  const point = async (id) => {
    await services.point({
      "lang": http.getLang(),
      "point": id
    }
    )
      .then(({ data }) => {
        if ('master' in data && data.master.length > 0) {
          setDataMaster({
            category: data.category,
            points: data.master,
            userHint: data.userHint,
            username: data.username,
            userpic: data.userpic,
            lang: data.lang
          })
          setItemFocuse(data.master[0]);
          setCenterMap({ lat: data.master[0].lat, lng: data.master[0].lon })
          if (map) {
            map.flyTo([data.master[0].lat, data.master[0].lon]);
          }
        }
      })
      .catch(err => {
      });
  }

  useEffect(() => {
    if (idPoint != "" && idPoint != null)
      point(idPoint)
  }, [idPoint]);

  const [dragging, setDragging] = useState(true);

  const master = async () => {
    setIsLoading(true);
    await services.master({
      "user": "null",
      "point": "null",
      "lat": "null",
      "long": "null",
      "lang": http.getLang(),
      "category": "1"
    }
    )
      .then(({ data }) => {
        setIsLoading(false);
        /*if (data.errorCode != 0) {
          toast.error(data.message, { theme: "colored" });
          return
        }*/
        setDataMaster(data)
        if ('points' in data && data.points.length > 0) {
          setItemFocuse(data.points[0]);
          setCenterMap({ lat: data.points[0].lat, lng: data.points[0].lon })
          if (map) {
            map.flyTo([data.points[0].lat, data.points[0].lon]);
          }
        }
      })
      .catch(err => {
        setIsLoading(false);
      });
  }



  const [map, setMap] = useState(null);
  const [currentPosition, setCurrentPosition] = useState(null);

  const POSITION_CLASSES = {
    bottomleft: 'leaflet-bottom leaflet-left',
    bottomright: 'leaflet-bottom leaflet-right',
    topleft: 'leaflet-top leaflet-left',
    topright: 'leaflet-top leaflet-right',
  }
  const CurrentLocation = ({ position, zoom }) => {
    //const parentMap = useMap();    
    //useMapEvent('click', onClick)
    //const handlers = useMemo(() => ({ move: onChange, zoom: onChange }), [])
    //useEventHandlers({ instance: parentMap }, handlers)
    const minimap = useMemo(() =>
    (
      <Link to="/"
        onClick={async (e) => {
          e.preventDefault();
          e.stopPropagation();
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              console.log('position', position);
              setCenterMap({ lat: position.coords.latitude, lng: position.coords.longitude });
              setCurrentPosition({ lat: position.coords.latitude, lng: position.coords.longitude })
              if (map) {
                map.flyTo([position.coords.latitude, position.coords.longitude], 15);
              }
            },
            async (error) => {
              const state = await navigator.permissions.query({ name: 'geolocation' }).then((result) => {
                console.log('result', result)
                return result.state;
              });
              console.log('state', state);
              if (state == 'granted') { return; }
              else if (state == 'prompt') {
                toast.error('اجازه دسترسی این برنامه به gps هنوز برقرار نشده');
                return;
              } else if (state == 'denied') {
                toast.error('دسترسی به gps برای این برنامه مسدود شده است');
                return state;
              }
              toast.error('خطای دسترسی به gps ');
              console.error("Error getCurrentPosition " + error);
            }, (setting) => { console.log('setting', setting) }
          );
        }}
      >
        <img src={currentLocation2} className='' /></Link>
    ), []);
    const positionClass = (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright
    return (
      <div className={positionClass + " customCurrentLocation"}>
        <div className="leaflet-control leaflet-bar1 ">{minimap}</div>
      </div>
    )
  }


  const lat_long = async (center) => {
    setIsLoading(true);
    await services.lat_long({
      "lat": center.lat,
      "long": center.lng,
      "lang": http.getLang(),
      "category": category
    }
    )
      .then(({ data }) => {
        setIsLoading(false);
        /*if (data.errorCode != 0) {
          toast.error(data.message, { theme: "colored" });
          return
        }*/
        const filter = data.points.filter(x => !dataMaster.points.some(a => a.lat == x.lat && a.lng == x.lng));
        console.log('filter', filter);
        const temp = { ...dataMaster };
        console.log('sum', [...dataMaster.points, ...filter]);
        temp.points = [...dataMaster.points, ...filter];
        setDataMaster({ ...temp })

      })
      .catch(err => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    if (!map || (idPoint != "" && idPoint != null)) {
      //console.log('category not map');
      return;
    }
    const center = map.getCenter();
    console.log("category Center", center);
    lat_long(center)
  }, [category])

  const MapEvents = () => {
    const map2 = useMapEvents({
      mousedown(e) {
        //console.log('mouse24', e)
      },
      mouseover(e) {
        //console.log('mouse22', e)
      },
      mousemove(e) {
        //console.log('mouse23', e)
      },
      dragend: (e) => {
        if (point != "" && point != null)
          return;
        const center = e.target.getCenter();
        console.log("mapCenter", center);
        console.log("map bounds", e.target.getBounds());
        lat_long(center)
      },
      click(e) {
        //const temp = [...locations];
        //setLocations([...temp, { latitude: e.latlng.lat, longitude: e.latlng.lng }])
        //map2.locate()
        console.log('click', e.latlng);
      },
      locationfound(e) {
        console.log('locationfound', e.latlng);
        //setPosition(e.latlng)
        //map.flyTo(e.latlng, map.getZoom())
      },
    });
    return false;
  }
  const [showHeader, setShowHeader] = useState(false)

  const onExpandFooter = (p) => {
    setShowHeader(!p);
  }


  return (
    <>
      {showHeader &&
        <HeaderMain
          loading={isLoading}
          data={dataMaster}
          category={category}
          onChangeCategory={x => {
            setIdPoint("")
            const temp = { ...dataMaster };
            temp.points = [];
            setDataMaster({ ...temp })
            setCategory(x.id);
            
          }}
        />}
      <FooterMain onExpand={onExpandFooter} data={itemFocuse} doExpand={doExpand} />
      <MapContainer
        center={[centerMap.lat, centerMap.lng]}
        dragging={dragging}
        zoom={10}
        zoomControl={false}
        style={{ height: "100vh" }}
        ref={setMap}
      >
        <TileLayer
          url={'https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'}
          subdomains={["mt0", "mt1", "mt2", "mt3"]}
          attribution='&copy; <a href="https://www.Google.com">Google</a> contributors'

        />
        <MapEvents />


        <CurrentLocation position="bottomright" />
        {currentPosition != null &&
          <Marker
            position={[currentPosition.lat, currentPosition.lng]}
          /* icon={new L.icon({
             iconUrl: icon,
             shadowUrl: iconShadow,
             iconSize: [35, 46],
             iconAnchor: [17, 46]
           })}*/
          />
        }
        {'points' in dataMaster && dataMaster.points.map((item, index) =>
          <Marker
            key={index + "fff"}
            position={[item.lat, item.lon]}
            icon={new L.icon({
              iconUrl: item.focuse ? item.icon_select : item.icon,
              //shadowUrl: iconShadow
              iconSize: [70, 70],
            })}
            eventHandlers={{
              //dragend: (e) => { eventHandlers(e, item, index) },
              click: (e) => {
                console.log('click marker', e);
                const temp = { ...dataMaster };
                temp.points = temp.points.map((x, i) => { x.focuse = false; return x; });
                console.log(temp);
                temp.points[index].focuse = true;
                setItemFocuse(dataMaster.points[index]);
                setDataMaster({ ...temp });
              },
              /*drag: (e) => {
                console.log('onDrag marker', e);
                //eventHandlers(e, item, index);
              },
              mouseover: () => {
                console.log('over marker');
              },
              mouseout: () => {
                console.log('out marker');
              },*/
            }}
          >
          </Marker>
        )}

      </MapContainer>
    </>
  );
}

