import iconDown from '../Images/icon11.png'
import iconDownExpand from '../Images/icon11Expand.png'
import addressIcon from '../Images/addressIcon.png'
import star1 from '../Images/star1.png'
import star2 from '../Images/star2.png'
import telIcon from '../Images/telIcon.png'
import webSiteIcon from '../Images/webSiteIcon.png'
//import picComment from '../Images/picComment.png'
import whatsapp from '../Images/whatsapp.png'
import signal from '../Images/signal.png'
import telegram from '../Images/telegram.png'
import pinterest from '../Images/pinterest.png'
import instagram from '../Images/instagram.png'
import map from '../Images/map.png'
import share from '../Images/share.png'
//import picPost from '../Images/picPost.png'
import like from '../Images/like.png'
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import React, { ChangeEvent, FocusEvent, useEffect, useState } from "react";
//import { isTemplateExpression } from 'typescript'

import http from "../Services/httpService";
import services from "../Services/services";
export default function FooterMain({ onExpand, data, doExpand }) {
  const [detail, setDetail] = useState({ id: 0 });


  const [isLoading, setIsLoading] = useState(false);
  const point = async (id) => {
    setIsLoading(true);
    setDetail({});
    await services.point({
      "lang": http.getLang(),
      "point": id
    }
    )
      .then(({ data }) => {
        setIsLoading(false);
        /*if (data.errorCode != 0) {
          toast.error(data.message, { theme: "colored" });
          return
        }*/
        setDetail({ ...data.master[0], points: data.points, comments: data.comments });
      })
      .catch(err => {
        setIsLoading(false);
      });
  }
  useEffect(() => {
    setDetail(data);
  }, [data])

  const [expand, setExpand] = useState(false);
  useEffect(() => {
    onExpand(expand);
    if (expand)
      point(detail.id)
  }, [expand])

  useEffect(() => {
    console.log('data', data)
  }, [data])


  useEffect(() => {
    if (doExpand)
      setExpand(p => !p);
  }, [doExpand])



  const Rating = (value) => {
    const stars = Array.from({ length: 5 }, () => <img src={star2} className='rating' />);
    for (let i = 0; i < value; i++) {
      stars[i] = <img src={star1} className='rating' />;
    }
    return <>{stars}</>;
  };

  const doShare = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigator
      .share({
        title: detail.name,
        text: detail.discription,
        url: "https://traWili.com?point=" + detail.id,
      })
      .then(() => console.log('Successful share! 🎉'))
      .catch(err => console.error(err));
  }

  const getMobileOperatingSystem = () => {
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  const doMap = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const browser = getMobileOperatingSystem();
    let link = document.createElement("a");
    link.setAttribute("href",`https://maps.google.com/?q=${detail.lat},${detail.lon}`);
    /*
      browser == "iOS"
        ? `https://maps.google/?q=${detail.lat},${detail.lon}`//`https://maps.apple.com/?ll=${detail.lat},${detail.lon}`
        : `https://maps.google.com/maps?q=loc:${detail.lat},${detail.lon}&z=15`
    );*/

    //link.setAttribute("target", "_blank");
    link.setAttribute("download", data);
    document.body.appendChild(link);
    link.click();


  }

  return (
    <>
      <div className='row leaflet-control '
        id={`${expand ? "customFooterExpand" : "customFooter"}`}
      >
        <div className='col'>

          <div className='row  d-flex justify-content-center'>
            <div className='col d-flex justify-content-center'>
              <Link
                className='d-flex justify-content-center'
                to=""
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setExpand(p => !p);
                }}>
                <img src={expand ? iconDownExpand : iconDown} className=' ' id="buttonFooter" />
              </Link>
            </div>
          </div>

          <div className='row'>
            <div className='col  ' id="colMainFooter">
              <div className='row'>
                <div className='col-auto '>
                  <img src={'pic' in detail ? detail.pic : null} className='picFooter' />
                </div>
                <div className='col'>
                  <div className='row'>
                    <div className='col-auto  '>
                      <div className='row mt-1 '>
                        <div className='col p-0'>
                          <div className='fs5 text-truncate fnBreak'>{detail.name}</div>
                        </div>
                      </div>
                      <div className='row  mt-1'>
                        <div className='col fs5 p-0'>
                          توضیحات
                        </div>
                      </div>
                    </div>
                    <div className='col p-0 d-flex justify-content-end align-items-center'>
                      <div className='border rounded p-2 myRounded shadow'>
                        {'stars' in detail && Rating(detail.stars)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {isLoading &&
                <div
                  className="spinner-border spinner-border-sm"
                  role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              }

              <div className='row mt-2'>
                <div className='col fs6 color1'>
                  {'points' in detail && detail.points.length > 0 && expand ? detail.points[0].discription : detail.discription}
                </div>
              </div>
              {expand &&
                <>
                  <div className='row mt-3'>
                    <div className='col'>
                      {'points' in detail && detail.points.length > 0 && expand &&
                        <iframe
                          style={{ borderRadius: "15px" }}
                          width="100%"
                          height="100%"
                          allowFullScreen={true}
                          webkitallowfullscreen="true"
                          mozallowfullscreen="true"
                          src={detail.points[0].video}>
                        </iframe>
                      }
                    </div>
                  </div>
                  <div className='row mt-3'>
                    {'points' in detail && detail.points.length > 0 && detail.points[0].address != "null" &&
                      <div className='col-12 col-sm-4 col-md-4 mb-3'>
                        <div className='row'>
                          <div className='col-auto'>
                            <img src={addressIcon} />
                          </div>
                          <div className='col'>
                            <div className='d-flex flex-row fs7'>آدرس</div>
                            <div className='d-flex flex-row fs8 color1'>{detail.points[0].address}</div>
                          </div>
                        </div>
                      </div>
                    }
                    {'points' in detail && detail.points.length > 0 && detail.points[0].phone != "null" &&
                      <div className='col-12 col-sm-4 col-md-4 mb-3'>
                        <div className='row'>
                          <div className='col-auto'>
                            <img src={telIcon} />
                          </div>
                          <div className='col'>
                            <div className='d-flex flex-row fs7'>تلفن</div>
                            <div className='d-flex flex-row fs8 color1'>{detail.points[0].phone}</div>
                          </div>
                        </div>
                      </div>
                    }
                    {'points' in detail && detail.points.length > 0 && detail.points[0].web != "null" &&
                      <div className='col-12 col-sm-4 col-md-4 mb-3'>
                        <div className='row'>
                          <div className='col-auto'>
                            <img src={webSiteIcon} />
                          </div>
                          <div className='col'>
                            <div className='d-flex flex-row fs7'>وب سایت</div>
                            <div className='d-flex flex-row fs8 color1'>{detail.points[0].web}</div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                  {'points' in detail && detail.points.length > 0 &&
                    <div className='row mt-3'>
                      <div className='col d-flex justify-content-between'>
                        {detail.points[0].whatsapp != 'null' && <Link to={detail.points[0].whatsapp} ><img src={whatsapp} className='iconSocial' /></Link>}
                        {/*detail.points[0].signal != 'null' && <Link to={detail.points[0].signal}  ><img src={signal} className='iconSocial' /></Link>*/}
                        {detail.points[0].telegram != 'null' && <Link to={detail.points[0].telegram}  ><img src={telegram} className='iconSocial' /></Link>}
                        {/*detail.points[0].pinterest != 'null' && <Link to={detail.points[0].pinterest}  ><img src={pinterest} className='iconSocial' /></Link>*/}
                        {detail.points[0].Instagram != 'null' && <Link to={detail.points[0].Instagram}  ><img src={instagram} className='iconSocial' /></Link>}
                        <Link to="/" onClick={doShare}><img src={share} className='iconSocial' /></Link>
                        {/*<a href={`geo:${detail.lat},${detail.lon}`} target="_blank"><img src={map} className='iconSocial' /></a>*/}
                        <Link to="/" onClick={doMap}><img src={map} className='iconSocial' /></Link>
                      </div>
                    </div>
                  }
                  <div className='row mt-4 pt-3'>
                    <div className='col-auto bg-white  fs7 p-4 tabPanel'  >
                      نظرات کاربران
                    </div>
                  </div>
                  <div className='row  '>
                    <div className='col  bg-white  p-3' style={{ borderTopLeftRadius: "24px" }}>
                      <div className='row'>
                        <div className='col-auto  fs9'>
                          مرتب سازی
                        </div>
                        <div className='col d-flex justify-content-end align-items-center'>
                          <button className='btn me-2 btn-active'>جدید ترین </button>
                          <button className='btn me-2'>دیدگاه خریداران</button>
                          <button className='btn'>مفید ترین</button>
                        </div>
                      </div>
                      <div className='row mt-3'>
                        <div className='col horzintalLine mx-3'>
                        </div>
                      </div>

                      {'comments' in detail && detail.comments.map((x, i) =>
                      (
                        <div className='row mt-3 fn-kalameh' key={i + "colll"}>
                          <div className='col '>
                            <div className='row'>
                              <div className='col-auto '>
                                <img src={x.userpic} className='picComment' />
                              </div>
                              <div className='col d-flex align-items-center justify-content-end d-none myFlex-d-sm-block '>
                                <div className='likeCaption'><img src={like} /> پیشنهاد می کنم  </div>
                              </div>
                              <div className='col d-none d-sm-block '>
                                <div className='d-flex flex-row  align-items-center '>
                                  <div className='flex-fill d-flex'>
                                    <div><div className='me-2 fs13 badge text-bg-success'>{x.star}</div></div>
                                    <div className='me-2 fs13 '>{x.date}</div>
                                    <div className='me-2 fs13 '>{x.username}</div>
                                    <div className='me-2  customBusget1 fs11 p-2 px-3'>خریداری</div>
                                  </div>
                                  <div className='likeCaption'><img src={like} /> پیشنهاد می کنم  </div>
                                </div>
                                <div className='d-flex flex-row fs20 fw-bold'>{x.titel}</div>
                                <div className='d-flex flex-row fs18'>{x.discription}</div>
                              </div>
                            </div>
                            <div className='row d-none my-d-sm-block '>
                              <div className='col '>
                                <div className='d-flex flex-row fs20 fw-bold'>{x.titel}</div>
                                <div className='d-flex flex-row fs18'>{x.discription}</div>
                                <div className='d-flex flex-row  align-items-center justify-content-between'>
                                  <div><div className='me-2 fs13 badge text-bg-success'>{x.star}</div></div>
                                  <div className='me-2 fs13 '>{x.date}</div>
                                  <div className='me-2 fs13 '>{x.username}</div>
                                  <div className='me-2  customBusget1 fs11 p-2 px-3'>خریداری</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                      )}
                    </div>
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

